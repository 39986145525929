@content6: content6;
.@{content6}-wrapper {
  min-height: 720px;
  background-color: #23254B;
  .@{content6} {
    height: 100%;
    display: flex;
    align-items: center;

    &-text {
      padding-left: 16px;
      color: #E7C794;
      blockquote{
        font-size: 28px;
        border-left: 8px solid #E7C794;
        margin: .5em 10px;
        padding: 0 10px;
        quotes: "\201C""\201D""\2018""\2019";
        padding: 10px 20px;
        line-height: 1.4;
      }
      
      blockquote:before {
        content: open-quote;
        display: inline;
        height: 0;
        line-height: 0;
        left: -10px;
        position: relative;
        top: 30px;
        color: #E7C794;
        font-size: 3em;
      }
      
      p{
        margin: 0;
      }
      
      footer{
        margin:0;
        text-align: right;
        font-size: 0.7em;
        font-style: italic;
      }


      min-height: 424px;
      >*.queue-anim-leaving {
        position: relative !important;
      }
      .title-h1 {
        position: relative;
        margin: 0 0 16px;
        text-align: left;
        font-size: 2em;
      }
      .title-content {
        position: relative;
        margin-bottom: 64px;
        text-align: left;
      }
      ul {
        position: relative;
        display: inline-block;
        li {
          margin-bottom: 24px;
          .@{content6}-icon {
            display: inline-block;
            width: 30px;
            height: 30px;
            position: absolute;
          }
          .@{content6}-title,
          .@{content6}-content {
            margin-left: 45px;
          }
          .@{content6}-title {
            font-size: 14px;
            margin-bottom: 10px;
          }
        }
      }
    }
  }
}

@media screen and (max-width: 767px) {
  .@{content6}-wrapper {
    height: 860px;
    overflow: hidden;
    .@{content6} {
      display: block;
      &-img,
      &-text {
        display: block;
        width: 100%;
      }
      &-text {
        blockquote{
          font-size: 20px;
          border-left: 8px solid #E7C794;
          margin: .5em 10px;
          padding: 0 10px;
          quotes: "\201C""\201D""\2018""\2019";
          padding: 10px 20px;
          line-height: 1.4;
        }
        
        blockquote:before {
          content: open-quote;
          display: inline;
          height: 0;
          line-height: 0;
          left: -10px;
          position: relative;
          top: 30px;
          color: #E7C794;
          font-size: 3em;
        }
        
        p{
          margin: 0;
        }
        
        footer{
          margin:0;
          text-align: right;
          font-size: 12px;
          font-style: italic;
        }

        >h1,
        >p {
          text-align: center;
        }
        > h1 {
          margin: 56px auto 16px;
        }
        p {
          margin-bottom: 32px;
        }
      }
      &-img {
        margin-top: 20px;
      }
    }
  }
}
