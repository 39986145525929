@banner5: banner5;

.@{banner5} {
  // 如果在第一屏且导航位置为 relative, 一屏为 height: calc(~"100vh - 64px");
  width: 100%;
  height: 100vh;
  position: relative;
  background: url("https://storage.bfytz.cn/storm-article-file/bb4769287d26a2358dbc843b3f43ce49.jpg") center;
  background-size: cover;
  overflow: hidden;
  display: flex;

  & &-page {
    padding: 1px 0 0;
  }

  &-title {
    font-size: 48px;
    line-height: 1.5;
    color: #5f9bf1;
    margin-bottom: 8px;

    &-wrapper {
      width: 50%;
      // max-width: 450px;
      // position: relative;
      font-family: 'PingFang SC', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Hiragino Sans GB', 'Microsoft YaHei', 'Helvetica Neue', Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol';
      display: flex;
      justify-content: center;
      align-items: center;

      .login-button {
        color: white;
        font-size: 2.5rem;
        border-radius: 36px;
        padding-left: 24px;
        padding-right: 24px;
        padding-top: 12px;
        padding-bottom: 12px;
        border-color: white;
        border-width: 1px;
        border-style: solid;
        cursor: pointer;
      }

      .login-button:hover {
        color: #5f9bf1;
        border-color: #5f9bf1;
      }
    }
  }

  .action-button {
    color: white;
    font-size: 1.8rem;
    border-radius: 36px;
    padding-left: 24px;
    padding-right: 24px;
    padding-top: 12px;
    padding-bottom: 12px;
    border-color: white;
    border-width: 1px;
    border-style: solid;
    cursor: pointer;
  }

  .action-button:hover {
    color: #5f9bf1;
    border-color: #5f9bf1;
  }

  &-guest-login {
    width: 100%;
    // max-width: 450px;
    // position: relative;
    font-family: 'PingFang SC', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Hiragino Sans GB', 'Microsoft YaHei', 'Helvetica Neue', Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol';
    display: flex;
    justify-content: center;
    align-items: center;
  }

  &-explain {
    color: #333;
    font-size: 24px;
    margin-bottom: 24px;
  }

  &-content {
    font-size: 18px;
    color: #666;
    margin-bottom: 48px;
  }

  &-button-wrapper {
    .@{banner5}-button {
      background-image: linear-gradient(to right bottom, #82b5ff 0%, #3c89f6 0%);
      box-shadow: 0 8px 24px fade(#5f9bf1, 35);
      border-radius: 20px;
      border: none;
      padding: 0 42px;
      line-height: 40px;
      height: 40px;
      transition: transform .3s, box-shadow .3s;
      &:hover {
        transform: translateY(-5px);
        box-shadow: 0 13px 24px fade(#5f9bf1, 45);
        background-image: linear-gradient(to right bottom, #82b5ff 0%, #3c89f6 0%);
      }
    }
  }

  &-image {
    overflow: hidden;
    width: 55%;
    max-width: 710px;
    position: absolute;
    top: 15%;
    right: 24px;
  }
}

@media screen and (max-width: 767px) {
  .@{banner5} {
    min-height: 500px;
    flex-direction: column;

    & &-page.home-page {
      padding-top: 1px;
      padding-bottom: 0;
    }

    &-title-wrapper {
      text-align: center;
      position: static;
      width: 100%;
      margin-top: 64px;
      left: 0;
      top: 0;
    }

    .login-button {
      color: white;
      font-size: 1.5rem;
      border-radius: 36px;
      padding-left: 12px;
      padding-right: 12px;
      padding-top: 8px;
      padding-bottom: 8px;
      border-color: white;
      border-width: 1px;
      border-style: solid;
      cursor: pointer;
    }

    .action-button {
      color: white;
      font-size: 0.9rem;
      border-radius: 36px;
      padding-left: 24px;
      padding-right: 24px;
      padding-top: 12px;
      padding-bottom: 12px;
      border-color: white;
      border-width: 1px;
      border-style: solid;
      cursor: pointer;
    }

    &-title {
      font-size: 32px;
    }

    &-explain {
      font-size: 18px;
    }

    &-content {
      font-size: 14px;
    }

    &-image {
      position: static;
      width: 100%;
      top: 0;
      right: 0;
      margin: 32px 0 24px;
    }
  }
}
